export enum AuthenticationErrorType {
    Unknown = "Unknown",
    InvalidUserOrPassword = "InvalidUserOrPassword",
    AuthenticationMethodNotAvailable = "AuthenticationMethodNotAvailable",
    EmailNotVerified = "EmailNotVerified"
}
export enum IdType {
    MenschID = "MenschID"
}
export enum Language {
    ab = "ab",
    aa = "aa",
    af = "af",
    ak = "ak",
    sq = "sq",
    am = "am",
    ar = "ar",
    an = "an",
    hy = "hy",
    as = "as",
    av = "av",
    ae = "ae",
    ay = "ay",
    az = "az",
    bm = "bm",
    ba = "ba",
    eu = "eu",
    be = "be",
    bn = "bn",
    bi = "bi",
    bs = "bs",
    br = "br",
    bg = "bg",
    my = "my",
    ca = "ca",
    ch = "ch",
    ce = "ce",
    ny = "ny",
    zh = "zh",
    cu = "cu",
    cv = "cv",
    kw = "kw",
    co = "co",
    cr = "cr",
    hr = "hr",
    cs = "cs",
    da = "da",
    dv = "dv",
    nl = "nl",
    dz = "dz",
    en = "en",
    eo = "eo",
    et = "et",
    ee = "ee",
    fo = "fo",
    fj = "fj",
    fi = "fi",
    fr = "fr",
    fy = "fy",
    ff = "ff",
    gd = "gd",
    gl = "gl",
    lg = "lg",
    ka = "ka",
    de = "de",
    el = "el",
    kl = "kl",
    gn = "gn",
    gu = "gu",
    ht = "ht",
    ha = "ha",
    he = "he",
    hz = "hz",
    hi = "hi",
    ho = "ho",
    hu = "hu",
    is = "is",
    io = "io",
    ig = "ig",
    id = "id",
    ia = "ia",
    ie = "ie",
    iu = "iu",
    ik = "ik",
    ga = "ga",
    it = "it",
    ja = "ja",
    jv = "jv",
    kn = "kn",
    kr = "kr",
    ks = "ks",
    kk = "kk",
    km = "km",
    ki = "ki",
    rw = "rw",
    ky = "ky",
    kv = "kv",
    kg = "kg",
    ko = "ko",
    kj = "kj",
    ku = "ku",
    lo = "lo",
    la = "la",
    lv = "lv",
    li = "li",
    ln = "ln",
    lt = "lt",
    lu = "lu",
    lb = "lb",
    mk = "mk",
    mg = "mg",
    ms = "ms",
    ml = "ml",
    mt = "mt",
    gv = "gv",
    mi = "mi",
    mr = "mr",
    mh = "mh",
    mn = "mn",
    na = "na",
    nv = "nv",
    nd = "nd",
    nr = "nr",
    ng = "ng",
    ne = "ne",
    no = "no",
    nb = "nb",
    nn = "nn",
    ii = "ii",
    oc = "oc",
    oj = "oj",
    or = "or",
    om = "om",
    os = "os",
    pi = "pi",
    ps = "ps",
    fa = "fa",
    pl = "pl",
    pt = "pt",
    pa = "pa",
    qu = "qu",
    ro = "ro",
    rm = "rm",
    rn = "rn",
    ru = "ru",
    se = "se",
    sm = "sm",
    sg = "sg",
    sa = "sa",
    sc = "sc",
    sr = "sr",
    sn = "sn",
    sd = "sd",
    si = "si",
    sk = "sk",
    sl = "sl",
    so = "so",
    st = "st",
    es = "es",
    su = "su",
    sw = "sw",
    ss = "ss",
    sv = "sv",
    tl = "tl",
    ty = "ty",
    tg = "tg",
    ta = "ta",
    tt = "tt",
    te = "te",
    th = "th",
    bo = "bo",
    ti = "ti",
    to = "to",
    ts = "ts",
    tn = "tn",
    tr = "tr",
    tk = "tk",
    tw = "tw",
    ug = "ug",
    uk = "uk",
    ur = "ur",
    uz = "uz",
    ve = "ve",
    vi = "vi",
    vo = "vo",
    wa = "wa",
    cy = "cy",
    wo = "wo",
    xh = "xh",
    yi = "yi",
    yo = "yo",
    za = "za",
    zu = "zu"
}
export enum LoginProvider {
    Unknown = "Unknown",
    Google = "Google",
    Twitter = "Twitter",
    Facebook = "Facebook",
    Microsoft = "Microsoft",
    LocalJwt = "LocalJwt"
}
export enum OrderDirection {
    Ascending = "Ascending",
    Descending = "Descending"
}
export enum Currency {
    EUR = "EUR"
}
export enum OrderStatus {
    Placed = "Placed",
    Accepted = "Accepted",
    Shipped = "Shipped",
    Received = "Received",
    Returned = "Returned",
    Cancelled = "Cancelled"
}
export enum PaymentMethod {
    Invoice = "Invoice"
}
export enum ShippingMethod {
    Standard = "Standard"
}
export enum AccountType {
    Local = "Local",
    LocalAnonymous = "LocalAnonymous",
    External = "External",
    Assigner = "Assigner",
    Admin = "Admin"
}
export enum UserType {
    Person = "Person",
    Assigner = "Assigner"
}
