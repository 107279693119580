import { TermsAndConditionsDE } from "./TermsAndConditions_DE";

interface TermsAndConditions_ENProps {}

export const TermsAndConditionsEN = (props: TermsAndConditions_ENProps) => {

    return (<TermsAndConditionsDE />);
    // return (
    // <>
    //     <h1>Terms and Conditions</h1>
    //     <p>
    //         Please only create one 웃ID for yourself.
    //     </p>
    // </>);

}